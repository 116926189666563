import { React, Button, useRef } from "../../services/common";
import Header from '../../components/Common/Header/Header';
import { Link } from "react-router-dom";
import { Card } from 'react-bootstrap';
import data from "./assets/categories.json";
import students_data from "./assets/students.json";
import courses from "./assets/courses.json"
import topfeatures from "./assets/features.json";
import musicseekho from "../../assets/images/footer/Music Seekho.svg";
import facebook from "../../assets/images/footer/Facebook.svg";
import instagram from "../../assets/images/footer/Instagram.svg";
import linkedin from "../../assets/images/footer/Linkedin.svg";
import twitter from "../../assets/images/footer/Twitter.svg";
import map from "../../assets/images/footer/Map Pin.svg";
import phone from "../../assets/images/footer/Phone.svg";
import emailicon from "../../assets/images/footer/Email.svg";
import arrowicon from "../../assets/images/arrow.svg";
import expert from "../../assets/images/trust-badges/Expert Instructor.svg";
import demo from "../../assets/images/trust-badges/Free Demo.svg";
import affordable from "../../assets/images/trust-badges/Affordable Pricing.svg";
import flexible from "../../assets/images/trust-badges/Flexible Scheduling.svg";

import "./Home.css";


const Home = () => {
    const contacts = data;
    const students = students_data;
    const features = topfeatures;
    const courseList = courses;
    const name = useRef();
    const message = useRef();
    const emailAddress = "meetings@musicseekho.com";
    const subject = "Query for Music Seekho";
    const benefitsArray = [
        "Music learning anytime, anywhere: convenience & flexibility",
        "Relax from daily stress with engaging musical activities",
        "Enhance focus, discipline with productive music immersion",
        "Gain Confidence through continuous improvement"];

    const sendEmail = () => {
        const body = encodeURIComponent(`Name: ${name.current.value}\n\n${message.current.value}`);
        window.location.href = `mailto:${emailAddress}?subject=${subject}&body=${body}`;
    };

    function FadeInSection(props) {
        const [isVisible, setVisible] = React.useState(false);
        const domRef = React.useRef();
        React.useEffect(() => {
            const observer = new IntersectionObserver(entries => {
                entries.forEach(entry => setVisible(entry.isIntersecting));
            });
            observer.observe(domRef.current);
        }, []);
        return (
            <div
                className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
                ref={domRef}>
                {props.children}
            </div>
        );
    }

    return (
        <>
            <Header home={true} />
            <div>
                <div>
                    <img className="w-100 landing-image" src="https://firebasestorage.googleapis.com/v0/b/musicapp-backend-5878b.appspot.com/o/homepage%2FMusic-learning-platform.webp?alt=media&token=d2e60cd0-c8cb-4e37-8d07-7d4cb4e8dcea" alt="Landing Page for Music Seekho">
                    </img>
                    <div className="main-heading">
                        <h1 className="headline">
                            <span>The Best experience
                                of <span className="main-keyword">Learning Music</span>
                            </span>
                        </h1>
                        <h2 className="headline-detail">
                            Elevate Your Musical Skills: Embrace the Future of Learning with Our Dynamic and Interactive Platform!
                        </h2>
                        <div className="headline-buttons">
                            <Link to="/tutors" className="get-started get-started-text">
                                <h3>Get Started</h3>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <g clipPath="url(#clip0_118_182)">
                                        <path d="M16.172 10.9999L10.808 5.63592L12.222 4.22192L20 11.9999L12.222 19.7779L10.808 18.3639L16.172 12.9999H4V10.9999H16.172Z" fill="white" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_118_182">
                                            <rect width="24" height="24" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </Link>

                            <Link to="https://www.youtube.com/channel/UCWt0amvTA9yQYj4Mc4sBnkQ" target="_blank" className="get-started-text">
                                <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M5.00195 16.9993C5.00195 23.6266 10.3741 28.9987 17.0013 28.9987C23.6285 28.9987 29.0007 23.6266 29.0007 16.9993C29.0007 10.3721 23.6285 5 17.0013 5C10.3741 5 5.00195 10.3721 5.00195 16.9993ZM23.7892 23.7872C21.9889 25.5875 19.5472 26.5988 17.0013 26.5988C14.4554 26.5988 12.0137 25.5875 10.2134 23.7872C8.41319 21.987 7.40182 19.5453 7.40182 16.9993C7.40182 14.4534 8.41319 12.0117 10.2134 10.2115C12.0137 8.41124 14.4554 7.39987 17.0013 7.39987C19.5472 7.39987 21.9889 8.41124 23.7892 10.2115C25.5894 12.0117 26.6008 14.4534 26.6008 16.9993C26.6008 19.5453 25.5894 21.987 23.7892 23.7872ZM21.2023 16.5998L15.3478 12.6976C15.2756 12.6494 15.1916 12.6217 15.1049 12.6175C15.0182 12.6132 14.932 12.6325 14.8554 12.6734C14.7788 12.7143 14.7147 12.7752 14.67 12.8496C14.6253 12.924 14.6016 13.0091 14.6014 13.096V20.9027C14.6017 20.9894 14.6255 21.0743 14.6701 21.1485C14.7148 21.2227 14.7787 21.2835 14.8551 21.3243C14.9315 21.3652 15.0175 21.3846 15.1041 21.3805C15.1906 21.3764 15.2744 21.349 15.3466 21.3011L21.2023 17.3989C21.2681 17.3551 21.3221 17.2957 21.3594 17.226C21.3968 17.1563 21.4163 17.0784 21.4163 16.9993C21.4163 16.9203 21.3968 16.8424 21.3594 16.7727C21.3221 16.703 21.2681 16.6436 21.2023 16.5998Z" fill="white" />
                                </svg>
                                <span className="watch-video">
                                    Watch Video</span>
                            </Link>
                        </div>

                        <div className="trusted-by">
                            <div className="badges">
                                <img src={expert} alt="Expert Tutors" />
                                <img src={demo} alt="Free Demo Classes" />
                                <img src={affordable} alt="Affordable Music Lessons" />
                                <img src={flexible} alt="Flexible Learning Schedules" />
                            </div>
                        </div>
                    </div>
                </div>
                <FadeInSection>
                    <div className="music-category">
                        <h2 className="explore">&nbsp; by Category</h2>
                        <div className="items">
                            {contacts.map((contact, index) => (
                                <Card key = {index} className={"categories " + contact.background} onMouseOver={(e) => e.currentTarget.querySelector('.category-image').src = contact.hoverIcon}
                                    onMouseOut={(e) => e.currentTarget.querySelector('.category-image').src = contact.icon}>
                                    <Card.Img className="category-image" variant="top" src={contact.icon}  alt={`Explore ${contact.type} lessons with expert tutors at Music Seekho`} />
                                    <Card.Body className="category-card">
                                        <span className="category-type">{contact.type}</span>
                                        <div className="category-about">{contact.about}</div>
                                        <Link to="/courses" className="course-link"> <span className="learn-more">Learn More
                                            <img className="arrow-learn-more" src={arrowicon} alt = "Learn More"></img>
                                        </span>
                                        </Link>
                                    </Card.Body>
                                </Card>

                            ))}
                        </div>
                    </div>
                </FadeInSection>

                <FadeInSection>
                    <div className="tutors">
                        <div className="row">
                            <div className="col-5 tutor-text">
                                <h2 className="our-tutors">Our Tutors</h2>
                                <h3 className="tutor-description">At our platform, our exceptional instructors, hailed as the best tutors, blend expertise, passion, and personalized guidance into every session. With interactive and fun teaching methods, whether beginner or advanced, unlock your full potential with tailored instruction. Join us for a transformative learning experience, empowering you to excel in your musical journey with confidence and creativity. Discover the magic of music today!</h3>
                                <Link className="view-tutors" to="/tutors">
                                    View All Tutors
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <g clipPath="url(#clip0_118_182)">
                                            <path d="M16.172 10.9999L10.808 5.63592L12.222 4.22192L20 11.9999L12.222 19.7779L10.808 18.3639L16.172 12.9999H4V10.9999H16.172Z" fill="white" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_118_182">
                                                <rect width="24" height="24" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </Link>
                            </div>
                            <div className="col-7 tutor-images">
                                <img className="w-100" src="https://firebasestorage.googleapis.com/v0/b/musicapp-backend-5878b.appspot.com/o/homepage%2FTutors-at-music-seekho.webp?alt=media&token=b449b62a-61a2-46c5-97a8-d496f59ece3f" alt="All Music Tutors List" >
                                </img>
                            </div>
                        </div>
                    </div>
                </FadeInSection>

                <FadeInSection>
                    <div className="top-features">
                        <div className="feature-body">
                            <h2 className="feature-heading">
                                Top Features you can get
                            </h2>
                            <h3 className="feature-text">
                                Explore our features to enhance your music learning with Music Seekho. Elevate your skills effortlessly.
                            </h3>
                            <Link to="/tutors" className="get-started-feature">
                                Get Started
                            </Link>
                        </div>
                        <div className="items">
                            {features.map((feature, index) => (
                                <Card key = {index} className="features-container">
                                    <Card.Img className="feature-image" variant="top" src={feature.image} alt={`Explore ${feature.heading} at Music Seekho - Learn more about this feature`} />
                                    <Card.Body className="category-card">
                                        <span className="feature-title">{feature.heading}</span>
                                        <div className="feature-about">{feature.about}</div>
                                    </Card.Body>
                                </Card>
                            ))}
                        </div>
                    </div>
                </FadeInSection>

                <FadeInSection>
                    <div className="top-courses">
                        <h2 className="course-headline">Our Most <span className="popular-courses">Popular Courses</span></h2>
                        <h3 className="course-title">Join our top-rated classes led by our accomplished instructors.</h3>
                        <div className="items">
                            {courseList.map((course, index) => (
                                <Link key = {index} to="/courses" className="course-link">
                                    <Card className="courses" >
                                        <Card.Img className="course-image" variant="top" src={course.course_image} alt={`Learn ${course.name} - A popular course at Music Seekho`}  />
                                        <Card.Body>
                                            {/* <Card.Img src={course.rating}></Card.Img> */}
                                            <div className="course-about">{course.about}</div>
                                            <hr></hr>
                                            <div>
                                                <Card.Img className="course-tutor" src={course.tutor_image}  alt={`Instructor ${course.tutor_name} for ${course.name} at Music Seekho`}></Card.Img>
                                                <span className="course-tutor-name">{course.tutor_name}</span>
                                                <div className="course-name">{course.name}</div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            ))}
                        </div>
                    </div>
                </FadeInSection>

                <FadeInSection>
                    <div className="benefits row">
                        <div className="col-6 benefit-section">
                            <img className="benefit-image" src="https://firebasestorage.googleapis.com/v0/b/musicapp-backend-5878b.appspot.com/o/homepage%2Fmusic-learning-platform-benefits.webp?alt=media&token=af3f3ea7-d537-486a-8768-5a02eb0398be" alt="Benefits of using our music platform"></img>
                        </div>
                        <div className="col-6 benefit-section">
                            <h2 className="benefit-heading">What Benefit You Will Get</h2>
                            {benefitsArray.map((benefit, index) => (
                                <div key = {index} className="benefit-list">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM15.6318 19.8961L21.8379 13.4961L20.4021 12.1039L14.8858 17.7926L11.5695 14.6357L10.1905 16.0843L14.2245 19.9243L14.9421 20.6074L15.6318 19.8961Z" fill="#7F56D9" />
                                    </svg><span className="benefit-text">{benefit}</span></div>
                            ))}
                        </div>
                    </div>
                </FadeInSection>

                <FadeInSection>
                    <div className="testimonials">
                        <h2 className="testimonial-heading">
                            Testimonials
                        </h2>
                        <h3 className="testimonial-title">What our student say about us</h3>
                        <div className="testimonial-container">
                            {students.map((student, index) => (
                                <Card key = {index} className="testimonial-card">
                                    <div className="testimonial-review">
                                        <Card.Img className="student-image" src={student.image} alt={`Image of ${student.name}, a Music Seekho student`}  />
                                        <Card.Title className="student-name">{student.name}</Card.Title>
                                    </div>
                                    <div>
                                        <Card.Text className="student-review">{student.review}</Card.Text>
                                    </div>
                                </Card>
                            ))}
                        </div>
                    </div>
                </FadeInSection>

                <FadeInSection>
                    <div className="footer-music">
                        <div className="row">
                            <div className="col-6 footer-container">
                                <img src={musicseekho} alt="Get in touch with Music Seekho, our music learning platform">
                                </img>
                                <div className="footer-company">
                                    © Music Seekho, 2023
                                </div>

                                <div className="social-icons">
                                    <Link to="https://www.facebook.com/people/Music-Seekho/100081724186098/?mibextid=LQQJ4d" target="_blank" >
                                        <img src={facebook} alt="Connect with Music Seekho on Facebook"></img>
                                    </Link>
                                    <Link to="https://twitter.com/musicseekho" target="_blank" >
                                        <img src={twitter} alt="Connect with Music Seekho on Twitter"></img>
                                    </Link>
                                    <Link to="https://in.linkedin.com/company/music-seekho" target="_blank" >
                                        <img src={linkedin} alt="Connect with Music Seekho on Linkedin"></img>
                                    </Link>
                                    <Link to="https://www.instagram.com/musicseekho" target="_blank" >
                                        <img src={instagram} alt="Connect with Music Seekho on Instagram"></img>
                                    </Link>
                                </div>
                                <div className="contact">
                                    <span className="contact-us">Contact Us</span>
                                    <p className="inquiry">
                                        Have an inquiry? We'll be happy to assist you.
                                    </p>
                                    <div className="contact-details">
                                        <span>
                                            <img className="contact-icons" src={phone} alt="Contact Details of Music Seekho, music teachinhg and learning platform"></img>
                                            +91 98 773 46698
                                        </span>
                                        <span>
                                            <img className="contact-icons" src={emailicon} alt="Email Address of Music Seekho"></img>
                                            meetings@musicseekho.com
                                        </span>
                                        <span>
                                            <img className="contact-icons" src={map} alt="Address of Music Seekho"></img>
                                            1532, Sector 70, Mohali, India
                                        </span>
                                    </div>
                                </div>

                            </div>
                            <div className="col-6 flex">
                                <div className="fill-form">
                                    <div className="fill-details">
                                        <div className="form-details">
                                            <div className="footer-form">
                                                Fill in your details
                                            </div>
                                            <label className="footer-label">
                                                Name
                                            </label>
                                            <input ref={name} type="text" className="footer-input">
                                            </input>
                                            <label className="footer-label message-label">
                                                Message
                                            </label>
                                            <input ref={message} type="text" className="footer-input message-input">
                                            </input>
                                            <Button className="submit-message" onClick={sendEmail}>Submit</Button>
                                        </div>
                                    </div>
                                </div>
                                {/* <div>
                                    <ul className="footer-navbar">
                                        <li>Home</li>
                                        <li>Company</li>
                                        <li>Blog</li>
                                        <li>Contact Us</li>
                                        <li>Sitemap</li>
                                    </ul>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </FadeInSection>
            </div>
        </>

    )
}

export default Home;

import { useEffect, useState } from "react";
import { Breadcrumbs, Link, Typography } from '@mui/material';
import { Link as RouterLink, useLocation } from "react-router-dom";
import Header from "../Header";

import "./MainHeader.css";


const MainHeader = ({ tutorName, courseName, showAuth }) => {

    const location = useLocation();
    const [breadcrumbs, setBreadcrumbs] = useState([]);

    useEffect(() => {
        const generateBreadcrumbs = async () => {
          const pathnames = location.pathname.split('/').filter(x => x);
          const breadcrumbItems = [];
    
          for (let i = 0; i < pathnames.length; i++) {
            let to = `/${pathnames.slice(0, i + 1).join('/')}`;
           let text = pathnames[i];
            // Handle dynamic segments based on your routes
            if (pathnames[0] === 'tutors' && i === 1) {
              text = tutorName; 
            //   await getTutorNameById(pathnames[i]);
            } else if (pathnames[0] === 'courses' && i === 1) {
              // Skip displaying the tutor ID in courses path
              continue;
            }

            else if (pathnames[0] === 'tutors' && i === 2) {
              text = courseName;
            }

            else if (pathnames[0] === 'courses' && i === 2) {
              text = courseName;
            }
             
            else {
              text = text.charAt(0).toUpperCase() + text.slice(1);
            }
    
            if (i === pathnames.length - 1) {
              breadcrumbItems.push(
                <Typography className="other-breadcrumb selected-breadcrumb" key={to}>
                  {text}
                </Typography>
              );
            } else {
              breadcrumbItems.push(
                <Link className="other-breadcrumb" key={to}  component={RouterLink} to={to}>
                  {text}
                </Link>
              );
            }
          }
    
          setBreadcrumbs(breadcrumbItems);
        };
    
        generateBreadcrumbs();
      }, [location, tutorName]);

    return (
        <>
          {<Header home = {false} ></Header> }  
            {/* <div className="main-navbar">
                <img className="navbar-logo" src={logo}></img>
            </div> */}
            <Breadcrumbs className="breadcrumbs" separator="›" aria-label="breadcrumb">
                <Link className="other-breadcrumb" underline="hover" key="home" color="inherit" component={RouterLink} to="/">
                    Home
                </Link>
                {breadcrumbs}
            </Breadcrumbs>
         
        
        </>
    )
}


export default MainHeader;
import { React, useEffect, useState, api, API_URL, PuffLoader, override, ToastContainer, toast, convertValiditiesToDate, StudentSidebar } from "../../../services/common"
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import StudentPayments from "../StudentPayments/StudentPayments";
import even from "../../../assets/images/even.svg";
import odd from "../../../assets/images/odd.svg";
import classcount from "../../../assets/images/classcount.svg"
import { useNavigate } from "react-router-dom";
import "./StudentHistory.css"

const StudentHistory = () => {
    const [tab, setTab] = useState(0);
    const [tutors, setTutors] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    let id = localStorage.getItem('uid');

    const changeTab = (event, newValue) => {
        setTab(newValue);
    };

    const openCourseDetails = (tutor) => {
        navigate(`/student-panel/history/${tutor.id}/${tutor.course}`, { state: { tutorData: tutor } });
    };


    const fetchCourseNameFromTutor = async (tutorId, courseId) => {
        try {
            const response = await api.get(API_URL + `/tutors/view-tutor/` + tutorId);
            const currentCourse = response.data?.courses.find(c => c.id === courseId);
            return currentCourse ? currentCourse.name : null;
        } catch (error) {
            console.log('Error fetching course name for tutor:', error);
            return null; // Return null if there's an error
        }
    };


    const getTutors = async () => {
        try {
            setLoading(true);
            
            // Await the API call to fetch student profile
            const response = await api.get(API_URL + "/students/view-student/" + id);
            
            setLoading(false);
            
            if (response.data.tutors) {
                const updatedTutors = convertValiditiesToDate(response.data.tutors);
                
                // Await all the API calls to fetch course names for tutors
                const updatedTutorsWithCourses = await Promise.all(
                    updatedTutors.map(async (tutor) => {
                        if (tutor.course) {
                            // Fetch course name for each tutor
                            const courseName = await fetchCourseNameFromTutor(tutor.id, tutor.course);
                            tutor.courseName = courseName || 'Unknown Course'; // Assign course name or fallback value
                        }
                        return tutor;
                    })
                );
                
                response.data.tutors = updatedTutorsWithCourses; // Update tutors with course names
                setTutors(updatedTutors);
            }
         
        
            
        } catch (error) {
            setLoading(false);
            toast.error(error?.response?.data?.message || error.message); ;
            console.log(error.message);
        }
    };
    useEffect(() => {
        getTutors();
    }, []);

    return (
        <>
            <div className="row main-frame">
                <PuffLoader loading={loading} cssOverride={override} color="#7F56D9" />
                <div className="col-3 padding-remove mobile">
                    <StudentSidebar></StudentSidebar>
                </div>
                <div className="col-9 mobile">
                    <div className="course-tabs">
                        <Tabs value={tab} onChange={changeTab} className="course-tab-display">
                            <Tab label="Courses" />
                            <Tab label="Payments" />
                        </Tabs>
                        {tab === 0 && <>
                            {tutors.map((tutor, index) => (
                                <div key={index} className="course-history" onClick={() => openCourseDetails(tutor)}>
                                    <div className="course-bar">
                                        <img key={index} src={index % 2 === 0 ? even : odd} alt={`Course ${index}`} />
                                        <div className="course-naming">
                                            <div className="course-name-bar">{tutor.courseName} | {tutor.name} </div>
                                            <div className="course-progress">
                                                <div className="progress-heading">Progress</div>
                                                <progress value={tutor.class_attended} max={tutor.total_class} />
                                            </div>
                                        </div>
                                        <div>
                                            {tutor.validity && <span className="validity-date">
                                                Valid Till: {tutor.validity}
                                            </span>}
                                            <div className="class-count">
                                                <img src={classcount} alt = "Number of Music Classes Attended"></img>
                                                {tutor.class_attended}/{tutor.total_class}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </>}
                        {tab === 1 &&
                            <StudentPayments></StudentPayments>
                        }
                    </div>
                </div>
            </div>
            <ToastContainer position="bottom-left"
                autoClose={2500} theme="colored" />
        </>
    )
}

export default StudentHistory;
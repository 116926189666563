import {auth} from '../firebase'
import {axios, API_URL} from "./common";

const api = axios.create({
  baseURL: API_URL
});

// Add interceptor to include Firebase token in requests
api.interceptors.request.use(
 
  async (config) => {
    const firebaseToken = await auth.currentUser?.getIdToken();
    if (firebaseToken) {
      config.headers.Authorization = `Bearer ${firebaseToken}`;
    }
    else{
      console.error('No user is signed in.');
    }
    return config;
  }
);

export default api;
import { React, useState, useEffect, api, API_URL, PuffLoader, override, ToastContainer, toast } from "../../../services/common";
import { AppointmentForm } from '@devexpress/dx-react-scheduler-material-ui';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import "./StudentAppointment.css"


const BasicLayout = ({ onFieldChange, onDelete, appointmentData, ...restProps }) => {
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const dateOptions = { day: 'numeric', month: 'long', year: 'numeric' };
    const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: false, timeZone: 'Asia/Kolkata' };
    const [availableTimeSlots, setAvailableTimeSlots] = useState([]);
    const [loading, setLoading] = useState(false);
    const [tutors, setTutors] = useState([]);
    const [selectedSlot, setSelectedSlot] = useState(0);
    const [selectedTutor, setSelectedTutor] = useState(0);
    const [selectedDate, setSelectedDate] = useState(dayjs(appointmentData.startDate));
    const [selectedDay, setSelectedDay] = useState(daysOfWeek[new Date(appointmentData.startDate).getDay()]);
    const [startDate, setStartDate] = useState((new Date(appointmentData.startDate)).toLocaleDateString('en-IN', dateOptions))
    const startTime = (new Date(appointmentData.startDate)).toLocaleTimeString('en-IN', timeOptions);
    const endTime = (new Date(appointmentData.endDate)).toLocaleTimeString('en-IN', timeOptions);
    const id = localStorage.getItem('uid');
    let tutorsArray = [];
    const currentSlot = {
        text: `${startTime} - ${endTime}`,
        day: selectedDay,
    }

    const fetchTutorSchedule = () => {
        if (appointmentData && appointmentData.tutor_id && appointmentData.course_id) {
            tutorSchedule(appointmentData.tutor_id, appointmentData.course_id);
        } else {
            getTutors();
        }
    }

    const tutorSchedule = (tutorId, courseId) => {
        if (!(tutorId)) {
            toast.error("Tutor ID or title is missing");
            return;
        }
        setLoading(true);
        api.get(API_URL + "/tutors/view-weekly-schedule/" + tutorId).then((response) => {
            setLoading(false);
            availableTime(response.data, courseId);
        })
            .catch((error) => {
                toast.error(error?.response?.data?.message || error.message); ;
                setLoading(false);
                console.log(error);
            });
    }

    const getTutors = () => {
        setLoading(true);
        api.get(API_URL + "/students/view-student/" + id).then((response) => {
            setLoading(false);
            if (response.data.tutors && response.data.tutors.length > 0) {
                setTutors(response.data.tutors);
                tutorsArray = response.data.tutors; // Check about this variable
                tutorSchedule(response.data.tutors[0].id, response.data.tutors[0].course);
            }
        })
            .catch((error) => {
                toast.error(error?.response?.data?.message || error.message); ;
                setLoading(false);
                console.log(error)
            });
    }

    const handleFieldChange = (startDate, endDate, slot, course, tutor_id, title) => {
        onFieldChange({
            startDate: startDate,
            endDate: endDate,
            slot: slot,
            course: course,
            tutor_id: tutor_id,
            title: title,
        });
    };

    const initialSlot = (filteredSchedule) => {
        const selectedTimeSlot = availableTimeSlots.length ? availableTimeSlots[selectedDay][selectedSlot] : filteredSchedule[selectedDay][selectedSlot];
        const { startDate, endDate } = combineDateAndTime(selectedDate.toDate(), selectedTimeSlot.text);
        handleFieldChange(startDate, endDate, selectedTimeSlot.text, appointmentData.course_id || tutorsArray[selectedTutor]?.course, appointmentData.tutor_id || tutorsArray[selectedTutor]?.id, appointmentData.title || tutorsArray[selectedTutor]?.courseName);
    }

    const handleRadioChange = (event) => {
        const selectedSlotIndex = parseFloat(event.target.value);
        setSelectedSlot(selectedSlotIndex);
        const selectedTimeSlot = availableTimeSlots[selectedDay][selectedSlotIndex];
        const { startDate, endDate } = combineDateAndTime(selectedDate.toDate(), selectedTimeSlot.text);
        handleFieldChange(startDate, endDate, selectedTimeSlot.text, appointmentData.course_id || tutors[selectedTutor]?.course, appointmentData.tutor_id || tutors[selectedTutor]?.id, appointmentData.title || tutors[selectedTutor]?.courseName);
    };

    const handleTutorChange = (event) => {
        const selectedIndex = event.target.value;
        setSelectedTutor(selectedIndex);
        tutorSchedule(tutors[selectedIndex].id, tutors[selectedIndex].course);
    };

    const availableTime = (schedule, course) => {
        let filteredDaySchedule = [];
        let filteredSchedule = [];
        schedule.forEach(day => {
            const dayOfWeek = Object.keys(day)[0];
            const daySchedule = day[dayOfWeek]?.schedule;
            filteredDaySchedule = daySchedule?.filter(slot => {
                const hasMatchingCourse = slot.course === course;
                const noCourseAttached = !slot.course;
                const isNotDemo = !slot.demo;
                const isAvailability = slot.students && slot.batch_limit ? slot.students.length < slot.batch_limit : true;
                return (hasMatchingCourse || noCourseAttached) && isNotDemo && isAvailability;
            });

            if (filteredDaySchedule.length > 0) {
                filteredSchedule[dayOfWeek] = filteredDaySchedule.map((slot, index) => ({
                    id: index,
                    text: `${slot.start_time} - ${slot.end_time}`,
                }));
            }
        });

        setAvailableTimeSlots(filteredSchedule);
        setSlot(filteredSchedule);
    }


    const setSlot = (filteredSchedule) => {
        let matchingId = 0;
        if (filteredSchedule.hasOwnProperty(currentSlot.day)) {
            const matchingSlot = filteredSchedule[currentSlot.day].find((slot) => slot.text === currentSlot.text);
            if (matchingSlot) {
                matchingId = matchingSlot.id;
            }
            setSelectedSlot(matchingId);
            initialSlot(filteredSchedule);
        }
    }

    const combineDateAndTime = (date, timeRange) => {
        if (!timeRange) {
            return {
                startDate: date,
                endDate: date,
            };
        }
        const [startTime, endTime] = timeRange.split(' - ');
        const [startHours, startMinutes] = startTime.split(':');
        const [endHours, endMinutes] = endTime.split(':');
        const startDateWithTime = new Date(date.getFullYear(), date.getMonth(), date.getDate(), startHours, startMinutes);
        const endDateWithTime = new Date(date.getFullYear(), date.getMonth(), date.getDate(), endHours, endMinutes);

        return {
            startDate: startDateWithTime,
            endDate: endDateWithTime,
        };
    };

    const onDateChange = (date) => {
        date = dayjs(date);
        const chosenDay = daysOfWeek[(date.$d).getDay()];
        let slot = chosenDay && availableTimeSlots[chosenDay] && availableTimeSlots[chosenDay][0] ? availableTimeSlots[chosenDay][0]?.text : null
        const { startDate, endDate } = combineDateAndTime(date.toDate(), slot);
        setSelectedDate(date);
        setStartDate((new Date(date.$d)).toLocaleDateString('en-IN', dateOptions));
        setSelectedDay(chosenDay);
        handleFieldChange(startDate, endDate, slot, appointmentData.course_id || tutors[selectedTutor]?.course, appointmentData.tutor_id || tutors[selectedTutor]?.id, appointmentData.title || tutors[selectedTutor]?.courseName);
    }

    useEffect(() => {
        fetchTutorSchedule();
    }, []);


    return (
        <>
            <PuffLoader loading={loading} cssOverride={override} color="#7F56D9" />
            <ToastContainer position="bottom-left"
                autoClose={2500} theme="colored" />
            <AppointmentForm.BasicLayout appointmentData={appointmentData} onFieldChange={onFieldChange} {...restProps}>
                <span className="schedule-heading">
                    
                    {tutors.length === 0 ? (
                        <span> {appointmentData.title} Class with {appointmentData.tutor_name}</span>
                    ) : (
                        <span className="tutor-dropdown">
                            <select
                                id="tutorDropdown"
                                value={selectedTutor}
                                onChange={handleTutorChange}
                            >
                                <option value="" disabled>Select a tutor</option>
                                {tutors.map((tutor, index) => (
                                    <option key={tutor.id} value={index}>
                                        {tutor.name} -  {tutor?.courseName}
                                    </option>
                                ))}
                            </select>
                        </span>
                    )}

                </span>
                {tutors.length === 0 ? (
                    <div >
                        <span className="time-slot">{startTime} - {endTime}</span>
                    </div>
                ) : (
                    <></>
                )}

                {tutors.length === 0 ? (
                    <span className="change-slot">Change Slot</span>
                ) : (
                    <span className="change-slot"> Select slot </span>)}
                <div className="appointment">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DateCalendar', 'DateCalendar']}>
                            <DemoItem>
                                <DateCalendar value={selectedDate} onChange={onDateChange} />
                            </DemoItem>
                        </DemoContainer>
                    </LocalizationProvider>
                </div>

                <div>
                    <span className="available-slots">
                        <span>
                            Available slot(s) from
                        </span>
                        <span className="chosen-date">
                            &nbsp;{startDate}
                        </span>
                    </span>

                    <div className="slot-area">
                        {availableTimeSlots[selectedDay]?.map((slot) => (
                            <div key={slot.id} className="slots">
                                <input
                                    type="radio"
                                    id={slot.id}
                                    value={slot.id}
                                    checked={selectedSlot === slot.id}
                                    onChange={handleRadioChange}
                                />
                                <label className="slot-label" htmlFor={slot.id}>{slot.text}</label>
                            </div>
                        ))}
                    </div>
                </div>
            </AppointmentForm.BasicLayout>
        </>


    );
}

export default BasicLayout;
import { React, useState, Button, Row, Col, PuffLoader, override, Form, useRef } from "../../services/common"
import { useAuth } from "./AuthContext";
import { useNavigate } from "react-router-dom";
import "./Auth.css";
import eye from "../../assets/images/eye.svg"

// Sign Up page for using app and creating account
const Signup = ({closeModal, handleSuccess, handleError}) => {
    const nameRef = useRef();
    const emailRef = useRef();
    const passwordRef = useRef();
    const passwordConfirmRef = useRef();
    const { signup, getClaims } = useAuth();
    const [loading, setLoading] = useState(false);
    const [passwordShown, setPasswordShown] = useState(false);
    const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (passwordRef.current.value !== passwordConfirmRef.current.value) {
            return handleError("Passwords do not match")
            
        }
        try {
            setLoading(true);
            await signup(emailRef.current.value, passwordRef.current.value).then(() => {
                getClaims().then((idTokenResult) => {
                    localStorage.setItem('uid', idTokenResult.claims.user_id);
                    navigate("/");
                    closeModal();
                    handleSuccess("Congratulations! Your account has been created successfully.")
                })
                setLoading(false);
            }
            )
        } catch {
            handleError("Failed to create an account");
            setLoading(false);
        }

        setLoading(false);
    }
    const togglePassword = (type) => {
        if (type) {
            setConfirmPasswordShown(!confirmPasswordShown);
        }
        else {
            setPasswordShown(!passwordShown);
        }
    };

    return (
        <>
            <Row>
                <Col className="auth-card">
                    <Form onSubmit={handleSubmit}>
                        <div className="or-section">
                            <hr className="line">
                            </hr>
                            <span className="or">or signup with </span>
                        </div>
                        <div>
                            <Form.Group className="fields">
                                <Form.Control className = "auth-fields" type="name" placeholder="Name" ref={nameRef} required />
                            </Form.Group >
                            <Form.Group className="fields">
                                <Form.Control className = "auth-fields" type="email" placeholder="Email" ref={emailRef} required />
                            </Form.Group >
                            <Form.Group className="fields">
                                <Form.Control className = "auth-fields" type={passwordShown ? "text" : "password"} placeholder="Password" ref={passwordRef} required />
                                <img src={eye} alt = "show password" onClick={() => togglePassword(0)} className="password-eye" />
                            </Form.Group>
                            <Form.Group className="fields">
                                <Form.Control className = "auth-fields" type={confirmPasswordShown ? "text" : "password"} placeholder="Confirm Password" ref={passwordConfirmRef} required />
                                <img src={eye} alt = "hide password"  onClick={() => togglePassword(1)} className="password-eye" />
                            </Form.Group>
                            <Button variant="dark" size="lg" disabled={loading} className="w-100 submit" type="submit">
                                Continue
                            </Button>
                        </div>
                    </Form>
                </Col>
            </Row>
            <PuffLoader loading={loading} cssOverride={override} color="#7F56D9" />
        </>
    )
}


export default Signup;